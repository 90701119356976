window.esign = window.esign || {};

esign.init = function () {
  var jsonFetcherWrapper = document.querySelector(".json-fetcher");
  $("html").removeClass("no-js").addClass("js");

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [768, 0],
    lazy: true,
  });

  esign.initScrollHandler();
  esign.cacheSelectors();

  esign.navigation();
  if (jsonFetcherWrapper) {
    esign.jsonFetcher(jsonFetcherWrapper);
  }
  esign.responsiveVideos();
  esign.isotope();

  esign.initMaps();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.handlePopup();

  objectFitImages();

  // sliders
  esign.initSliders();

  // call once so all handlers will run once
  $(window).resize();
  $(window).scroll();
};

esign.initSliders = function () {
  $(document).ready(function () {
    $(".js-spotlight-slider").slick({
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.1,
          },
        },
      ],
    });
  });
};

esign.createCookie = function (name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";

  document.cookie = name + "=" + value + expires + "; path=/";
};

esign.readCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

esign.sortResultDropdown = function () {
  var sortDropdownActive = false;

  $(window).click(function () {
    sortDropdownActive = false;
    if (!sortDropdownActive) checkSort();
  });

  $("#sortResults").on("click", function (e) {
    e.stopPropagation();
    sortDropdownActive = !sortDropdownActive;
    checkSort();
  });

  function checkSort() {
    if (sortDropdownActive) {
      $(".offer-results__dropdown-content").css("display", "block");
    } else {
      $(".offer-results__dropdown-content").css("display", "none");
    }
  }
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $nav: $(".js-mobile-nav"),
  };
};

esign.navigation = function () {
  $(".main-nav__trigger").click(function (e) {
    e.preventDefault();
    var $mainNavWrap = $(this).next(".js-mobile-nav");
    if (parseInt($mainNavWrap.css("width")) === 0) {
      $mainNavWrap.addClass("open-mobile");
      $("html").addClass("has-open-nav");
    } else {
      $mainNavWrap.removeClass("open-mobile");
      $("html").removeClass("has-open-nav");
    }
  });
};

esign.jsonFetcher = function jsonFetcher(element) {
  var template = element.querySelector(".json-fetcher__template");
  var keys = element.dataset.keys.split(";");
  var api = element.dataset.api;

  $.get(api, function (data) {
    data.data.forEach(function (item) {
      var templateClone = template.cloneNode(true);
      keys.forEach(function (key) {
        try {
          var keyParts = key.split(".");
          var index = 0;
          var data = item;
          while (index + 1 <= keyParts.length) {
            data = data[keyParts[index]];
            index += 1;
          }
          templateClone.innerHTML = templateClone.innerHTML.replace(
            "__" + key + "__",
            data
          );
        } catch (error) {
          console.error(error);
        }
      });
      templateClone.innerHTML = templateClone.innerHTML.replace(
        "data-src",
        "src"
      );
      element.appendChild(templateClone.children[0]);
    });
    template.parentNode.removeChild(template);
  });
};

esign.responsiveVideos = function () {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

esign.isotope = function () {
  $grid = $(".travel-guide-isotope");
  $grid.isotope({
    itemSelector: ".grid__item",
    layoutMode: "masonry",
    transitionDuration: 0,
    masonry: {
      columnWidth: ".large--one-third",
    },
  });
  $(document).ready(function () {
    $grid.addClass("active");
  });
};

// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude, zoom) {
  if (!zoom) {
    zoom = 12;
  }
  var disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {
      name: "Styled Map",
    }),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set("map_style", styledMap);
  map.setMapTypeId("map_style");

  google.maps.event.addListenerOnce(map, "bounds_changed", function (event) {
    if ($("body").hasClass("guide")) {
      if (this.getZoom() > 7) {
        this.setZoom(7);
      }
    }
  });

  google.maps.event.addDomListener(window, "resize", function () {
    map.fitBounds(bounds);
  });

  return map;
}

var bounds;
var prev_infowindow = false;
function addContactGoogleMapsMarker(
  map,
  latitude,
  longitude,
  title,
  externUrl,
  image
) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: base_url + "assets/build/images/marker.png",
      size: new google.maps.Size(28, 37),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 37),
      scaledSize: new google.maps.Size(28, 37),
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon,
    });

  var contentString;
  var imageString =
    image != null
      ? "<div class='visual'><div class='visual__img' style='background-image: url(\"" +
        image +
        "\")'></div></div>"
      : "";
  contentString =
    "<div class='iw-content'>" +
    imageString +
    "<div class='iw-content__inner'><span class='iw-content__title'>" +
    title +
    '</span><div><a href="' +
    externUrl +
    '">Meer info</a></div></div></div>';

  var infowindow = new google.maps.InfoWindow({
    content: contentString,
    maxWidth: 233,
  });

  google.maps.event.addListener(marker, "click", function () {
    if (prev_infowindow) {
      prev_infowindow.close();
    }
    prev_infowindow = infowindow;
    infowindow.open(map, this);
  });

  google.maps.event.addListener(infowindow, "domready", function () {
    var $l = $(".iw-content");
    $l.parent().parent().parent().parent().addClass("iw-container");
  });

  if (bounds != null) {
    bounds.extend(myLatlng);
    map.fitBounds(bounds);
  }

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: "Xplore the north",
    lat: 51.016648,
    long: 3.784835,
    externUrl:
      "https://www.google.be/maps/place/Xplore+the+North/@51.0164719,3.7841786,18z/data=!4m8!1m2!2m1!1sBrusselsesteenweg+153A+001+B-9090+Melle!3m4!1s0x47c3f1d80ca7aba5:0xbb5041eaf2e19fc0!8m2!3d51.016621!4d3.784832",
  };

  if ($("#map").length > 0) {
    if ($("body").hasClass("contact")) {
      var map = addContactGoogleMaps("map", mapData.lat, mapData.long);
      addContactGoogleMapsMarker(
        map,
        mapData.lat,
        mapData.long,
        mapData.title,
        mapData.externUrl
      );
    } else if ($("body").hasClass("guide")) {
      bounds = new google.maps.LatLngBounds();
      var map = addContactGoogleMaps("map", 57.461577, -110.97676, 4);
      for (i = 0; i < markers.length; i++) {
        var marker = markers[i];
        addContactGoogleMapsMarker(
          map,
          marker.lat,
          marker.lng,
          marker.title,
          marker.link,
          marker.image
        );
      }
    }
  }
};

// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $(".form-ajax").submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr("action"), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find(".result").html(data.result);
        $form.find('button, input[type="submit"]').removeAttr("disabled");
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $(".form-ajax-recaptcha").submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass("validate")) {
      if ($form.validationEngine("validate") == false) {
        return false;
      }
    }

    if (
      $form.find('[name="g-recaptcha-response"]').length &&
      $form.find('[name="g-recaptcha-response"]').val() != ""
    ) {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find(".g-recaptcha").data("widgetid"));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr("disabled", "disabled");
  form_data = $form.serializeArray();

  $.post($form.attr("action"), form_data, function (data) {
    $("li").removeClass("error");
    var $result = $form.find(".result");

    if (data.errors === false) {
      if ($form.attr("id") === "form-newsletter") {
        $form.html(data.result);
      } else {
        $(".main").html(data.result);
      }

      if (typeof ga === "function") {
        ga(
          "send",
          "pageview",
          window.location.origin + window.location.pathname + "/bedankt"
        );
        ga("send", "pageview", $form.attr("action"));
      }

      //todo GTM trigger event
    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $(
            'input[name="' + field + '"],textarea[name="' + field + '"]'
          ).addClass("error");
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr("disabled");
  }).fail(function (response) {
    alert("Error: " + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($("#form-contact"));
};
var submitRecaptchaOrder = function (token) {
  esign.recaptchaFormSend($("#form-order"));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($("#form-newsletter"));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $(".g-recaptcha").each(function (i, el) {
    var attributes = {
      sitekey: $(el).data("sitekey"),
      size: $(el).data("size"),
      callback: $(el).data("callback"),
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data("widgetid", widgetid);
  });
};

// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if (esign.cache.isMobile) {
    $(".validate").validationEngine();
  } else {
    $(".validate").validationEngine({ scroll: false });
  }
};

// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
    isMobile =
      deviceAgent.match(/(iphone|ipod|ipad)/) ||
      deviceAgent.match(/(android)/) ||
      deviceAgent.match(/(iemobile)/) ||
      deviceAgent.match(/blackberry/i) ||
      deviceAgent.match(/bada/i) ||
      /OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent);

  if (isMobile) {
    $("html").addClass("mobile");
  } else {
    $("html").addClass("no-mobile");
  }

  return isMobile;
};

// ---------------------------------------
// ---------------- Cookies --------------
// ---------------------------------------

function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";

  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

// ---------------------------------------
// ---------------- Popups ---------------
// ---------------------------------------

esign.handlePopup = function (hideOrShow) {
  if (readCookie("SalePopup") == null) {
    $(".js-popup").addClass("is-visible");

    hidePopup = function () {
      $(".js-popup").removeClass("is-visible");
      createCookie("SalePopup", "seen", 1);
    };
  }

  $(".js-popup-close").on("click", function () {
    hidePopup();
  });
};

// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

require([
  "js/libs/plugins/isotope.min",
  "requirejs/require",
  "jquery/dist/jquery",
  "jquery-bridget/jquery-bridget",
  "js/polyfills/native-console",
  "response.js/response",
  "jquery-touchswipe/jquery.touchSwipe",
  "js/libs/validation/languages/jquery.validationEngine-nl",
  "js/libs/validation/jquery.validationEngine",
  "js/googlemaps-styles",
  "js/libs/plugins/slick.min",
  "js/libs/plugins/simplebar",
  "js/libs/plugins/ofi.min",
  "js/app/CanadaApp",
  "js/global",
  "js/travels",
  "js/travel-detail",
  "js/wishlist",
  "js/accordion",
], function (Isotope, require, jquery, jQueryBridget) {
  // Initialize on docready
  // make Isotope a jQuery plugin
  jQueryBridget("isotope", Isotope, $);
  // now you can use $().isotope()
  window.canadaApp = new CanadaApp();
  canadaApp.start();
  $(esign.init);
  $(esign.initGlobal);
  if ($("body").hasClass("travel-detail")) {
    $(esign.initTravelDetail);
  }
  $(esign.initTravels);
  $(esign.initWishlist);
  if ($("body").hasClass("excursions")) {
    $(esign.initAccordion);
  }
});

define("../resources/assets/js/esign", function(){});

