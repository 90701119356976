function CanadaApp() {
  this.wishlist = new Wishlist();
  this.currentJourney = null;
}

CanadaApp.prototype.start = function() {
  // when on travel detail page
  this.currentJourney = new Journey('0');

  // always
  this.wishlist = new Wishlist();

  if(journey_id != null) {
    canadaApp.currentJourney.setId(parseInt(journey_id)); // journey_id filled in view
  }
  $('.overview-card__options .option:not(.option--hidden)').each(function() {
    canadaApp.currentJourney.addExcursion(new Excursion($(this).data('excursion-id')));
  });

  $('.wishlist-item').each(function() {
    var journey = new Journey($(this).data('journey-id'));
    $(this).find('.wishlist-item__option').each(function() {
      journey.addExcursion(new Excursion($(this).data('option-id')));
    });
    canadaApp.wishlist.addJourney(journey);
  });

};


require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/app/EventEmitter',
  'js/app/Excursion',
  'js/app/Journey',
  'js/app/Wishlist'
], function() {
});


define("js/app/CanadaApp", function(){});

