window.esign = window.esign || {};

esign.initAccordion = function () {
  var accordionActive = 'accordion__title--active';
  document.querySelectorAll('.accordion__title').forEach(function (trigger) {
    try {
      trigger.addEventListener('click', function(e)  {
        e.preventDefault();
        var content = trigger.nextElementSibling;
        var contentInputs = content.querySelectorAll('input');

        // check if accordion doenst contain active class, open dropdown
        if (!trigger.classList.contains(accordionActive)) {
          trigger.classList.add(accordionActive);
          content.style.height = content.scrollHeight + 'px';
        } else {
          trigger.classList.remove(accordionActive);
          content.style.height = 0;
        }
      });
    } catch (error) {
      console.error(error);
    }
  });
};

define("js/accordion", function(){});

