window.esign = window.esign || {};

if($('body').hasClass('travel-detail')) {
  esign.initTravelDetail = function () {
    esign.askQuotationButton();
    esign.initChangeCount();
    esign.initOverviewCards();
    esign.initTravelSchemeSlider();
    esign.initExcursions();
  };

  esign.initChangeCount = function() {
    var $changeCount = $('.js-excursion-count');

    canadaApp.currentJourney.eventEmitter.on('excursion-added', function(excursionId, count) {
      $changeCount.text(count);
    });
    canadaApp.currentJourney.eventEmitter.on('excursion-removed', function(excursionId, count) {
      $changeCount.text(count);
    });

    $changeCount.text($('.option:not(.option--hidden)').length);

  };

  esign.initOverviewCards = function() {
    var $overviewCard = $('#travel-detail-overview-card');
    if($overviewCard.length) {
      var $option = $overviewCard.find('.option');

      $('#overview-card-show-trigger').click(function() {
        $(this).closest('.overview-card-wrap').addClass('js-show-overview-card');
      });

      $('#overview-card-close-trigger').click(function() {
        $(this).closest('.overview-card-wrap').removeClass('js-show-overview-card');
      });

      $option.each(function () {
        var $close = $(this).find('button.icon-close');
        var $optionEl = $(this);
        $close.click(function () {
          canadaApp.currentJourney.removeExcursion($optionEl.data('excursion-id'));
          $optionEl.addClass('option--hiding');
          setTimeout(function () {
            $optionEl.addClass('option--hidden');
          }, 200);
          $.post(wishlist_endpoint + '/add',$('input.journey-extra').serializeArray());
        });
      });

      var $minMaxOptionsIcon = $('#travel-detail-overview-card').find('.overview-card__options-title .icon');
      if(parseInt($('.js-excursion-count').text()) === 0) {
        $minMaxOptionsIcon.hide();
      }

      canadaApp.currentJourney.eventEmitter.on('excursion-added', function (excursionId, count) {
        $option.filter($('[data-excursion-id="'+ excursionId + '"]')).removeClass('option--hidden option--hiding');
        if (count === 1) {
          $minMaxOptionsIcon.show();
        }
      });
      canadaApp.currentJourney.eventEmitter.on('excursion-removed', function (excursionId, count) {
        var $deletedOption = $option.filter($('[data-excursion-id="'+ excursionId + '"]'));
        $deletedOption.addClass('option--hiding');
        setTimeout(function () {
          $deletedOption.addClass('option--hidden');
        }, 200);
        if (count === 0) {
          $minMaxOptionsIcon.hide();
        }
      });
    }
  };

  esign.initTravelSchemeSlider = function() {
    $('.travel-scheme-slider').each(function() {
      var $travelSchemeSlider = $(this);
      var $actions = $('#' + $(this).data('actions'));
      var $actionLeft = $actions.find('.slider-action--left');
      var $actionRight = $actions.find('.slider-action--right');
      var originalCount = $travelSchemeSlider.find('.slider__slide').length;

      $travelSchemeSlider.on('init', function(event, slick){
        $(this).addClass('initialized');
        slick.$slides.click(function() {
          if(! $(this).hasClass('.slick-current')) {
            slick.goTo(parseInt($(this).data('slick-index')));
          }
        });
      }.bind(this));

      $travelSchemeSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var cssHidden = {'visibility': 'hidden', 'opacity': 0};
        var cssVisible = {'visibility': 'visible', 'opacity': 1};
        if(nextSlide === 0) {
          $actionLeft.css(cssHidden);

          if(originalCount > 1) {
            $actionRight.css(cssVisible);
          }
        } else if (nextSlide === originalCount - 1) {
          $actionRight.css(cssHidden);
          $actionLeft.css(cssVisible);
        } else {
          $actionLeft.css(cssVisible);
          $actionRight.css(cssVisible);
        }
      }.bind(this));

      $travelSchemeSlider.on('afterChange', function(event, slick, currentSlide) {
      }.bind(this));

      var slick = $(this).slick({
        slidesToShow: 1,
        infinite: false,
        draggable: false,
        speed: 200,
        initialSlide: 0,
        prevArrow: $actionLeft,
        nextArrow: $actionRight,
        waitForAnimate: false,
        accessibility: false,
        lazyLoad: 'ondemand'
      });
    });
  };

  esign.initExcursions = function() {
    $('.js-excursion').each(function() {
      var $checkBox = $(this).find('input[type="checkbox"]');
      var excursionId = $(this).data('excursion-id');
      var $overviewCardWrap = $('.overview-card-wrap');

      $checkBox.change(function() {
        if($(this).is(':checked')) {
          canadaApp.currentJourney.addExcursion({id: excursionId});
          $overviewCardWrap.addClass('js-show-overview-card');
        } else {
          canadaApp.currentJourney.removeExcursion(excursionId);
        }
        $.post(wishlist_endpoint + '/add',$('input.journey-extra').serializeArray());
      });

      canadaApp.currentJourney.eventEmitter.on('excursion-added', function(excursionIdAdded, count) {
        if(excursionIdAdded === excursionId) {
          $checkBox.prop('checked', true);
        }
      });
      canadaApp.currentJourney.eventEmitter.on('excursion-removed', function(excursionIdRemoved, count) {
        if(excursionIdRemoved === excursionId) {
          $checkBox.prop('checked', false);
        }
      });
    });
  };

  esign.askQuotationButton = function() {
    $('.ask-quotation-button').click(function() {
      $.post(wishlist_endpoint + '/add',$('input.journey-extra').serializeArray(), function() {
        window.location = $(this).data('href');
      }.bind(this));
    });
  }
}
;
define("js/travel-detail", function(){});

