window.esign = window.esign || {};

esign.initWishlist = function () {
  esign.initWishlistButton();

  if($('body').hasClass('wishlist')) {
    esign.initWishlistItem();
  }
};

esign.initWishlistButton = function() {
  $('.wishlist-button').click(function(e) {
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && $(this).hasClass('wishlist-added')) {
      window.location.href = $(this).find('a').attr('href');
      return;
    }

    if(!$(e.target).is('a, a *')) {
      $(this).toggleClass('wishlist-added');
      $.get($(this).data('action'));
    }
  });
};

esign.initWishlistItem = function() {
  canadaApp.wishlist.eventEmitter.on('journey-removed', function(journeyId, count) {
    if(count === 0) {
      $('.hide-when-wishlist-empty').css({'display': 'none'});
      $('.wishlist-empty').addClass('js-visible');
    }
  });

  var $contact = $('.wishlist-contact');
  var quotationCheckedCount = 0;

  $('.wishlist-item').each(function() {
    var $wishlistItem = $(this);
    var $optionsToggle = $(this).find('.wishlist-item__options-toggle');
    var originalOptionsToggleText = $optionsToggle.text();
    var $options = $(this).find('.wishlist-item__options');
    var $optionItems = $options.find('.wishlist-item__option');
    var $wishlistItemClose = $wishlistItem.find('.wishlist-item__close');
    var journeyId = $wishlistItem.data('journey-id');
    var journey = canadaApp.wishlist.journeys[parseInt(journeyId)];
    var $wishlistItemCheckbox = $(this).find('.wishlist-item-checkbox');

    $optionsToggle.click(function() {
      if(!$optionItems.length) {
        $wishlistItem.find('.wishlist-item__options-empty').show();
      }

      $options.slideToggle();
      $options.toggleClass('js-active');
      $optionsToggle.toggleClass('js-active');

      if($optionsToggle.text() === originalOptionsToggleText) {
        $optionsToggle.text('Verberg opties');
      } else {
        $optionsToggle.text(originalOptionsToggleText);
      }
    });

    $optionItems.each(function() {
      var $option = $(this);
      var $closeOptionButton = $(this).find('.wishlist-item__option-close');
      $closeOptionButton.hover(function() {
        $option.addClass('js-remove-intent');
      }, function() {
        $option.removeClass('js-remove-intent');
      });

      $closeOptionButton.click(function() {
        $option.addClass('js-remove');
        $option.find('input').removeAttr('checked');
        $.post(wishlist_endpoint + '/add',$('input.journey-extra').serializeArray());
        setTimeout(function(){
          $option.remove();
          if($wishlistItem.find('.wishlist-item__option').length === 0) {
            $wishlistItem.find('.wishlist-item__options-empty').show();
          }
        }, 340);
      });
    });

    $wishlistItemClose.hover(function() {
      $wishlistItem.addClass('js-remove-intent');
    }, function() {
      $wishlistItem.removeClass(('js-remove-intent'));
    });

    $wishlistItemClose.click(function() {
      $wishlistItem.addClass('js-remove');
      canadaApp.wishlist.removeJourney(journeyId);
      response = $.post(wishlist_endpoint + '/remove/' + journeyId);
      setTimeout(function() {
        $wishlistItem.remove();
        location.reload();
      }, 340);
    });

    // var _quotationCheckLogic = function($input, ignoreCount) {
    //   if($input.is(':checked')) {
    //     quotationCheckedCount++;
    //     $contact.removeClass('wishlist-contact--disable');
    //   } else {
    //     if(!ignoreCount) {
    //       quotationCheckedCount--;
    //     }
    //     if(quotationCheckedCount <= 0) {
    //       $contact.addClass('wishlist-contact--disable');
    //     }
    //   }
    // };

    // $wishlistItemCheckbox.find('input').change(function() {
    //   _quotationCheckLogic($(this));
    // });

    // $wishlistItemCheckbox.click(function() {
    //   $.post(wishlist_endpoint + '/add',$('input.journey-extra').serializeArray());
    // });

    // _quotationCheckLogic($wishlistItemCheckbox.find('input'), true); // run once on page load
  });
};

define("js/wishlist", function(){});

