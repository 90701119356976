window.esign = window.esign || {};

esign.initGlobal = function () {
  esign.scrollOnPageLinks();
  esign.initDisplayToggle();
  esign.initCheckboxItem();

  esign.initTravelCard();
  esign.initFooterButtons();
  esign.initSlick();
  esign.initPhotoGallery();
  esign.initFullscreenPopup();
};


esign.initScrollHandler = function() {
  var $pageHeader = $('.page-header');
  var previousTop = 0;
  var lastDownValue = 0;
  $(window).scroll(function(e) {
    var top = $(this).scrollTop();
    var scrollDirection = top > previousTop ? 'down' : 'up';
    previousTop = top;
    if(top > 130  && scrollDirection === 'down') {
      $pageHeader.addClass('page-header--hide');
      lastDownValue = top;
    } else {
      if(lastDownValue - top > 120) {
        $pageHeader.removeClass('page-header--hide');
      }
    }
  });
};

esign.scrollOnPageLinks = function() {
  var jump = function(e)
  {
    if (e){
      var target = jQuery(this).attr("href").replace('/', '');
    }else{
      var target = location.hash;
    }

    $('html,body').animate(
      {
        scrollTop: (jQuery(target).offset().top) - 100
      },300,function()
      {
        //location.hash = target;
      });
  };

  $('a.scroll-on-page-link').click(jump);

  if (location.hash){
    jump();
  }
};

esign.initDisplayToggle = function() {
  var $toggle = $('.js-display-toggle');
  $toggle.each(function() {
    var $toggleEl = $(this);
    var $content = $($toggleEl.data('display-content'));
    $toggleEl.click(function() {
      if (!$content.is(':visible') || $content.css('visibility') === 'hidden') {
        $content.addClass('js-display-visible');
        $toggleEl.addClass('js-display-toggle--open');

        // Exceptions:
        if($toggleEl.hasClass('travels__filters-toggle')) {
          $('html').addClass('js-opened-filters');
        }
      } else {
        $content.removeClass('js-display-visible');
        $toggleEl.removeClass('js-display-toggle--open');
        // Exceptions:
        if($toggleEl.hasClass('travels__filters-toggle')) {
          $('html').removeClass('js-opened-filters');
        }
      }
    });
  });
};

esign.initCheckboxItem = function() {
  $('.checkbox-item').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var $input = $(this).find('input');
    $(this).find('input')[0].checked =  ! $input.is(':checked');
    $input.trigger('change');
  });
};

esign.initTravelCard = function() {
  $('.travel-card__top ').hover(
    function() {
      $(this).addClass('top-hovered');
    },
    function() {
      $(this).removeClass('top-hovered');
    }
  );
};

esign.initFooterButtons = function() {
  var $personalAdviceWrap = $('.btn-personal-advice-wrap');
  var $personalAdvice = $('.page-footer__personal-advice');
  $('#btn-personal-advice').click(function(){
    $personalAdviceWrap.addClass('js-opening-advice');
    setTimeout(function() {
      $personalAdvice.addClass('js-open-left');
    }, 150);

    setTimeout(function() {
      $personalAdvice.addClass('js-open-up');
    }, 350);
  });

  $('#btn-personal-advice-close').click(function() {
    $personalAdvice.removeClass('js-open-up');
    setTimeout(function() {
      $personalAdvice.addClass('js-close-left');
    }, 400);
    setTimeout(function(){
      $personalAdvice.removeClass('js-close-left js-open-left');
      $personalAdviceWrap.removeClass('js-opening-advice');
    }, 550);
  });

  $personalAdviceWrap.addClass('js-initialized');

  var $scrollTopButton = $('#btn-scroll-to-top');
  $scrollTopButton.click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $(this).blur();
    return false;
  });

  $scrollTopButton.addClass('js-initialized');
};

esign.initSlick = function() {

  $(document).on('keydown', function(e) {
    if($('body').hasClass('js-has-fullscreen-popup')) {
      if(e.keyCode == 37) {
        $('.fullscreen-popup.js-open').find('.slick-slider').first().slick('slickPrev');
        return;
      } else {
        $('.fullscreen-popup.js-open').find('.slick-slider').first().slick('slickNext');
        return;
      }
    } else {
      $('.slick-slider').each(function() {
        if(e.keyCode == 37) {
          if($(this).is(':visible')) {
            $(this).slick('slickPrev');
            return;
          }
        }
        if(e.keyCode == 39) {
          if($(this).is(':visible')) {
            $(this).slick('slickNext');
            return;
          }
        }
      });
    }
  });

  $('.default-slider').each(function() {
    var $actions = $('#' + $(this).data('actions'));
    var $actionLeft = $actions.find('.slider-action--left');
    var $actionRight = $actions.find('.slider-action--right');
    var $dotsParent = $('#' + $(this).data('dots-parent'));

    var config = {
      slidesToShow: 1,
      arrows: false,
      draggable: false,
      autoplaySpeed: 4000,
      autoplay: true,
      speed: 800,
      initialSlide: 0,
      slidesToScroll: 1,
      accessibility: false,
      infinite: true,
      lazyLoad: 'progressive',
      dots: true,
    }

    if($actionLeft.length && $actionRight.length) {
      config.nextArrow = $actionRight;
      config.prevArrow = $actionLeft;
    }

    if($dotsParent.length) {
      config.appendDots = $dotsParent
    }

    var slick = $(this).slick(config);
  });


  $('.gallery-photo-slider').each(function() {
    var asNavForId = $(this).data('as-nav-for');
    var $galleryPhotoSlider = $(this);
    var originalCount = $galleryPhotoSlider.find('.slider__slide').length;

    $galleryPhotoSlider.on('init', function(event, slick){
      $(this).addClass('initialized');
    }.bind(this));

    $galleryPhotoSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var isNextOrPrevious;
      var slideCount = originalCount - 1;
      var nextSlideIsFirst = currentSlide === slideCount;
      var nextSlideIsLast  = nextSlide === slideCount;
      var $activeSlide = $galleryPhotoSlider.find('.slick-active');
      if (nextSlideIsFirst) {
        $activeSlide.next('.slider__slide').addClass('slick-clone-current');
      }
      if (nextSlideIsLast) {
        $activeSlide.prev('.slider__slide').addClass('slick-clone-current');
      }

      if (currentSlide === slideCount && nextSlide === 0 || currentSlide === 0 && nextSlide === slideCount) {
        isNextOrPrevious = true;
      } else if (nextSlide - 1 === currentSlide) {
        isNextOrPrevious = true;
      } else if (nextSlide + 1 === currentSlide) {
        isNextOrPrevious = true;
      }

      if(isNextOrPrevious) {
        $galleryPhotoSlider.addClass('js-short-animation');
      }
    }.bind(this));

    $galleryPhotoSlider.on('afterChange', function(slick, currentSlide) {
      $galleryPhotoSlider.removeClass('js-short-animation');
      $galleryPhotoSlider.find('.slick-clone-current').removeClass('slick-clone-current');
    }.bind(this));

    var slick = $(this).slick({
      slidesToShow: 1,
      asNavFor: '#' + asNavForId,
      arrows: false,
      draggable: false,
      speed: 0,
      initialSlide: 0,
      slidesToScroll: 1,
      accessibility: false,
      infinite: false,
      lazyLoad: 'ondemand'
    });
  });

  $('.gallery-thumb-slider').each(function() {
    var $actions = $('#' + $(this).data('actions'));
    var $actionLeft = $actions.find('.slider-action--left');
    var $actionRight = $actions.find('.slider-action--right');
    var asNavForId = $(this).data('as-nav-for');
    var $galleryThumbSlider = $(this);
    var originalCount = $galleryThumbSlider.find('.slider__slide').length;

    $galleryThumbSlider.on('init', function(event, slick){
      $(this).addClass('initialized');
    }.bind(this));

    $galleryThumbSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var cssHidden = {'visibility': 'hidden', 'opacity': 0};
      var cssVisible = {'visibility': 'visible', 'opacity': 1};
      if(nextSlide === 0) {
        $actionLeft.css(cssHidden);

        if(originalCount > 1) {
          $actionRight.css(cssVisible);
        }
      } else if (nextSlide === originalCount - 1) {
        $actionRight.css(cssHidden);
        $actionLeft.css(cssVisible);
      } else {
        $actionLeft.css(cssVisible);
        $actionRight.css(cssVisible);
      }
    }.bind(this));

    $galleryThumbSlider.on('afterChange', function(slick, currentSlide) {
    }.bind(this));

    var slickObj = $(this).slick({
      slidesToShow: 1,
      variableWidth: true,
      draggable: false,
      asNavFor: '#' + asNavForId,
      focusOnSelect:true,
      speed: 300,
      initialSlide: 0,
      centerMode: true,
      nextArrow: $actionRight,
      prevArrow: $actionLeft,
      infinite: false,
      waitForAnimate: false,
      accessibility: false,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            arrows: false,
            variableWidth: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            variableWidth: true
          }
        }
      ]
    });
  });

};

var _checkHeight = function($popup) {
  var $scrollContainer = $popup.find('.fullscreen-popup__scroll-container');
  var contentHeight = $scrollContainer.height();
  if(contentHeight > $(window).height()) {
    $scrollContainer.addClass('js-larger-than-window');
  } else {
    $scrollContainer.removeClass('js-larger-than-window');
  }
}.bind(this);


var scrollTopBeforeOpen = $(window).scrollTop();
esign.initFullscreenPopup = function() {

  // open popup on click
  $('.fullscreen-popup-trigger').click(function(e) {
    e.preventDefault();
    var $popup = $('#' + $(this).data('popup'));
    $popup.addClass('js-open');
    $popup.find('.slick-slider').slick('refresh');
    $('body').addClass('js-has-fullscreen-popup');
    $('html').addClass('js-fullscreen-overlay');
    $(window).resize();

    // check height
    _checkHeight($popup);

    scrollTopBeforeOpen = $(window).scrollTop();

    var urlReplace = "#" + $(this).data('popup'); // make the hash the id of the mdal shown
    history.pushState(null, null, urlReplace); // push state that hash into the url
  });

  var _closePopup = function($popup) {
    $popup.removeClass('js-open');
    $('body').removeClass('js-has-fullscreen-popup');
    $('html').removeClass('js-fullscreen-overlay');
    location.hash = '';
    $(window).scrollTop(scrollTopBeforeOpen);
  };

  // close popup on click
  $('.fullscreen-popup__close').click(function() {
    _closePopup($(this).closest('.fullscreen-popup'));
  });

  // close modal on browser previous click
  $(window).on('popstate', function() {
    var $openPopup = $('.fullscreen-popup.js-open');
    if ($openPopup.length) {
      _closePopup($openPopup);
    }
  });

  $('.fullscreen-popup').click(function(e) {
    if(this === e.target) {
      _closePopup($(this));
    }
  });

  $('.fullscreen-popup').each(function() {
    var $popup = $(this);
    $(window).resize(function(){_checkHeight($popup)});
    _checkHeight($popup);
  });

  $(document).keyup(function(e) {
    var KEYCODE_ESC = 27;
    if (e.keyCode === KEYCODE_ESC)  {
      _closePopup($('.fullscreen-popup.js-open'));
    }
  });

  // open modal from hash
  var hash = window.location.hash;
  var itemId = hash.replace('#','');
  $('.fullscreen-popup-trigger[data-popup="' + itemId + '"]').trigger('click');

};

var _galleryWindowResizeHandler = function() {
  $gallery = $('#photo-gallery').find('.gallery');
  var windowHeight = $(window).height();
  var windowWidth = $(window).width();
  var resizeWidthBasedOnHeight = (windowHeight - 300) * 1.5;
  var resizeWidth = resizeWidthBasedOnHeight <= (windowWidth - 80) ? resizeWidthBasedOnHeight : (windowWidth-80);
  if(resizeWidth > 1500) {
    resizeWidth = 1500;
  }

  $gallery.css({'width': resizeWidth + 'px' });
};

esign.initPhotoGallery = function () {
  $('.photo-gallery-trigger').click(function() {
    var $gallery = $('#' + $(this).data('popup'));
    $gallery.find('.gallery-photo-slider').get(0).slick.slickGoTo(parseInt($(this).data('go-to'), false));
    $('html').addClass('js-fullscreen-overlay');
  });

  $(window).resize(_galleryWindowResizeHandler);
  $(window).resize();
};


define("js/global", function(){});

