function Wishlist() {
  this.journeyCount = 0;
  this.journeys = {};
  this.eventEmitter = new EventEmitter();
}

Wishlist.prototype.addJourney = function(journey) {
  this.journeys[journey.id] = journey;
  this.journeyCount++;
  this.eventEmitter.emit('journey-added', journey.id,  this.journeyCount);
};

Wishlist.prototype.removeJourney = function(id) {
  delete this.journeys[id];
  this.journeyCount--;
  this.eventEmitter.emit('journey-removed', id, this.journeyCount);
};

define("js/app/Wishlist", function(){});

