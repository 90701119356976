function Journey(id) {
  this.id = id;
  this.excursionCount = 0;
  this.excursions = {};
  this.eventEmitter = new EventEmitter();
}

Journey.prototype.setId = function(id) {
  this.id = id;
};

Journey.prototype.addExcursion = function(excursion) {
  this.excursions[excursion.id] = excursion;
  this.excursionCount++;
  this.eventEmitter.emit('excursion-added', excursion.id, this.excursionCount);
};

Journey.prototype.removeExcursion = function(id) {
  delete this.excursions[id];
  this.excursionCount--;
  this.eventEmitter.emit('excursion-removed', id, this.excursionCount);
};

define("js/app/Journey", function(){});

