window.esign = window.esign || {};

esign.initTravels = function () {
  esign.initTravelsFilters();
};

esign.initTravelsFilters = function() {
  var $selectedFiltersWrap = $('.travels__filters-selected-wrap');
  var $selectedFilters = $selectedFiltersWrap.find('.travels__filters-selected li');
  var $resetButton = $selectedFilters.find('#reset-filters');
  var $filterCheckboxes = $('.filter-item input[type="checkbox"]');
  var $filterForm = $('#filters-form');

  $resetButton.click(function() {
    $filterCheckboxes.prop('checked', false);
    $selectedFiltersWrap.html('');
    submitForm();
  });

  $selectedFilters.each(function() {
    var $self = $(this);
    var linkedCheckboxId = $(this).data('linked-to');
    var $checkbox = $('#' + linkedCheckboxId);
    $(this).find('button').click(function() {
      $checkbox.prop('checked', false);
      $self.remove();
      if($('.travels__filters-selected li').length === 1) {
        $selectedFiltersWrap.html('');
      }
      submitForm();
    })
  });

  $filterForm.find('.checkbox-item input').on('change', function(e) {
    if($(e.target).hasClass('js-checkbox-region') == false) {
      submitForm();
    }
  });

  $filterForm.find('.js-checkbox-region').on('change', function(e) {
    var $content = $(e.target).closest('.accordion').children('.accordion__content');
    var $inputs = $content.find('.checkbox-item input');
    if($(e.target).prop('checked')) {
      // $(e.target).prop('checked', true)
      $inputs.each(function() {
        $( this ).prop('checked', true);
      });
    } else {
      $inputs.each(function() {
        $( this ).prop('checked', false);
      });
    }
    submitForm();
  });

  function submitForm() {
    $filterForm.submit();
  }

};

define("js/travels", function(){});

